<template>
  <div>
    <Navigation/>
    <!-- AI作画 -->
    <div class="bg_box">
      <div class="noneBox"></div>
      <div class="box">
        <p class="back" @click="$router.push('/aiNav')">返回</p>

        <div class="ct_box flex">
          <div class="form_upload_box">
            <ai_title ai_title="“智能Ai数字人-定制照片数字人生成"/>
            <div class="form_title_1">
              <div>定制照片数字人生成</div>
            </div>
            <div class="form_box_1">
              <!--内容录入、语音上传、照片上传-->
              <div class="form_content_1">
                <div class="form_content_1_1">
                  <p>*内容录入</p>
                  <el-input
                      placeholder="请录入数字人播报的文本，字符长度不超过 2000。"
                      v-model="form.textarea"
                      :rows="6"
                      maxlength="2000"
                      type="textarea"
                      clearable:true
                      resize="none"
                      show-word-limit
                  >
                  </el-input>
                </div>
                <div class="form_content_1_2">
                  <p>语音上传</p>
                  <div>
                    <el-upload
                        class="upload-demo"
                        action="/api/common/upload"
                        :headers="headers"
                        :before-upload="beforeUploadAudio"
                        :on-success="uploadAudioSuccess"
                        :on-error="uploadAudioError"
                        v-if="form.audioFile === ''"
                        style="height: 33px;overflow: hidden"
                    >
                      <el-button size="small" type="primary" plain><i class="el-icon-plus"></i>上传音频</el-button>
                    </el-upload>
                    <div v-else class="alreadyUploadAudio">
                      <div class="fileName">{{ audioFileName }}</div>
                      <i class="el-icon-error" @click="changeAudioFile"></i>
                    </div>
                  </div>

                  <div class="form_content_1_2_1">
                    <p>1.时长不超过 10 分钟</p>
                    <p>2.文件大小不超过120 MB</p>
                    <p>3.支持音频格式为wav、mp3、wma、m4a、aac</p>
                  </div>
                </div>
                <div class="form_content_1_3">

                  <div class="form_content_1_3_2">
                    <img :src="form.picFile" v-if="form.picFile!==''"/>
                  </div>
                  <div class="form_content_1_3_3">
                    <el-upload
                        class="upload-demo"
                        action="/api/common/upload"
                        :headers="headers"
                        :before-upload="beforeUploadPic"
                        :on-success="uploadPicSuccess"
                        :on-error="uploadPicError"
                        style="height: 33px;width:120px;overflow: hidden;"
                    >
                      <el-button size="small" type="primary" style="width: 120px" plain>
                        <span v-if="form.picFile===''"><i class="el-icon-plus"></i>上传照片</span>
                        <span v-else>重新选择</span>
                      </el-button>
                    </el-upload>
                  </div>
                </div>
              </div>

              <!--音色选择-->
              <div class="form_content_4">
                <div class="form_content_4_1">
                  <div class="form_content_4_1_1">
                    <span class="form_content_4_title">*音色选择</span>
                    <el-select v-model="form.timbre">
                      <el-option
                          v-for="item in timbreOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="form_content_4_1_2" v-if="form.timbre !==''">
                    <span class="form_content_4_title">*试听</span>
                    <audio controls src="#"></audio>
                  </div>
                </div>
                <div class="form_content_4_3">
                  <div class="form_content_4_3_left">

                    <div class="form_content_4_3_left_Item">
                      <div class="form_content_4_3_left_Item_top">
                        <span style="margin-right: 10px">*语速</span>
                        <el-slider
                            :min="0"
                            :max="15"
                            v-model="form.speed"
                            :step="1"
                        >
                        </el-slider>
                        <span style="margin-left: 10px">{{ form.speed }}</span>
                      </div>
                      <div class="form_content_4_3_left_Item_bottom">
                        5是正常值，0-15的取值范围。越大语速越快，默认值5 （字面量需要是整数）
                      </div>
                    </div>

                    <div class="form_content_4_3_left_Item">
                      <div class="form_content_4_3_left_Item_top">
                        <span style="margin-right: 10px">*音量</span>
                        <el-slider
                            :min="0"
                            :max="15"
                            v-model="form.volume"
                            :step="1"
                        >
                        </el-slider>
                        <span style="margin-left: 10px">{{ form.volume }}</span>
                      </div>

                      <div class="form_content_4_3_left_Item_bottom">
                        5是正常值，0-15的取值范围，越大音量越大，默认值5 （字面量需要是整数）
                      </div>
                    </div>
                    <div class="form_content_4_3_left_Item">
                      <div class="form_content_4_3_left_Item_top">
                        <span style="margin-right: 10px">*语调</span>
                        <el-slider
                            :min="0"
                            :max="15"
                            v-model="form.intonation"
                            :step="1"
                        >
                        </el-slider>
                        <span style="margin-left: 10px">{{ form.intonation }}</span>
                      </div>
                      <div class="form_content_4_3_left_Item_bottom">
                        5是正常值，0-15的取值范围，越大声音越尖，默认值5 （字面量需要是整数）
                      </div>
                    </div>
                  </div>
                  <div class="form_content_4_3_right">
                    <div class="form_content_4_3_right_1">示例照片</div>
                    <div class="form_content_4_3_right_2">
                      <div class="form_content_4_3_right_2_l">
                        <img src="@/assets/ai/example/1.png">
                      </div>
                      <div class="form_content_4_3_right_2_r">
                        <p>·照片清晰，只有单个人脸</p>
                        <p>·面部无明显阴影，无头发遮挡</p>
                        <p>·背景无其他人脸、杂物</p>
                        <p>·面部正对镜头，轮廓完整</p>
                        <p>·无夸张表情，不露牙齿</p>
                      </div>
                    </div>
                    <div class="form_content_4_3_right_3">
                      <div class="form_content_4_3_right_3_item">
                        <img src="@/assets/ai/example/2.png">
                        <span><i style="color: rgb(48,191,19)" class="el-icon-success"></i>五官清晰</span>
                      </div>
                      <div class="form_content_4_3_right_3_item">
                        <img src="@/assets/ai/example/3.png">
                        <span><i style="color: red" class="el-icon-error"></i>不是正面</span>
                      </div>
                      <div class="form_content_4_3_right_3_item">
                        <img src="@/assets/ai/example/4.png">
                        <span><i style="color: red" class="el-icon-error"></i>有遮挡</span>
                      </div>
                      <div class="form_content_4_3_right_3_item">
                        <img src="@/assets/ai/example/5.png">
                        <span><i style="color: red" class="el-icon-error"></i>过于模糊</span>
                      </div>
                    </div>

                  </div>
                </div>


              </div>

              <!--生成-->
              <div class="result_box">
                <div class="result_content">

                </div>
                <el-button type="primary" style="width: 500px;">生成视频</el-button>
              </div>


            </div>

          </div>
          <aiNav/>
        </div>
      </div>
    </div>
    <footerBot/>
  </div>
</template>

<script>
import aiNav from '@/components/aiNav.vue'
import ai_select from '@/components/ai_select.vue'
import ai_title from '@/components/ai_title.vue'
import ai_img from '@/components/ai_img'
import ai_switch from '@/components/ai_switch'
import ai_button_file_data from '@/components/ai_button_file_data'
import router from "@/router";

export default {
  mounted() {
    this.getUserInfo()
    console.log('form', this.form)
  },
  components: {
    aiNav,
    ai_select,
    ai_title,
    ai_img,
    ai_switch,
    ai_button_file_data
  },
  watch: {
    'form.XPosition': {
      handler(newVal) {
        let num = Number(newVal)
        console.log(num)
        if (isNaN(num)) {
          this.form.XPosition = '0'
        }
      }
    },
    'form.YPosition': {
      handler(newVal) {
        let num = Number(newVal)
        console.log(num)
        if (isNaN(num)) {
          this.form.YPosition = '0'
        }
      }
    },
    'form.scaling': {
      handler(newVal) {
        let num = Number(newVal)
        console.log(num)
        if (isNaN(num)) {
          this.form.scaling = '1'
        }
      }
    }

  },
  data() {
    return {
      headers: {
        token: '',
      },
      // 音频文件名
      audioFileName: '',
      // 是否开启字幕选项列表
      subtitlesOptions: [
        {
          label: '否',
          value: '0'
        },
        {
          label: '是',
          value: '1'
        },
      ],
      // 音色选项列表
      timbreOptions: [
        {
          label: '女',
          value: '女'
        },
        {
          label: '男',
          value: '男'
        }
      ],
      form: {
        // 内容录入
        textarea: '',
        //   音频文件
        audioFile: '',
        //   照片
        picFile: '',
        //   音色
        timbre: '',
        //   语速
        speed: 5,
        //   音量
        volume: 5,
        //   语调
        intonation: 5,
      },

    }
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.curlGet('/api/user/info').then((res) => {
        console.log('用户信息', res)
        if (res.data.code == 1) {
          this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage

          this.headers.token = res.data.data.token
        } else {
          console.log('未登录')
          this.$router.push({
            path: '/'
          })
        }
      })
    },

    //   音频文件上传
    // 上传前的验证函数
    beforeUploadAudio(file) {
      const isAllowedType = this.checkFileType_audio(file);
      const isAllowedSize = this.checkFileSize_audio(file);
      return new Promise((resolve, reject) => {
        if (isAllowedType && isAllowedSize) {
          this.checkFileDuration(file)
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                this.$message.error(error);
                reject(false);
              });
        } else {
          if (!isAllowedType) {
            this.$message.error('仅支持 wav、mp3、wma、m4a、aac 格式的音频文件');
          }
          if (!isAllowedSize) {
            this.$message.error('文件大小不能超过 120 MB');
          }
          reject(false);
        }
      });
    },
    // 检查文件类型-音频
    checkFileType_audio(file) {
      console.log(file)
      const allowedTypes = ['audio/wav', 'audio/mp3', 'audio/mpeg', 'audio/wma', 'audio/m4a', 'audio/aac'];
      return allowedTypes.includes(file.type);
    },
    // 检查文件大小-音频
    checkFileSize_audio(file) {
      const maxSize = 120 * 1024 * 1024; // 120 MB
      return file.size <= maxSize;
    },
    // 检查文件时长-音频
    checkFileDuration(file) {
      return new Promise((resolve, reject) => {
        const audio = new Audio(URL.createObjectURL(file));
        audio.addEventListener('loadedmetadata', () => {
          const duration = audio.duration;
          if (duration <= 600) { // 10 分钟 = 600 秒
            resolve();
          } else {
            reject('音频时长不能超过 10 分钟');
          }
          URL.revokeObjectURL(audio.src);
        });
        audio.addEventListener('error', () => {
          reject('无法获取音频时长');
          URL.revokeObjectURL(audio.src);
        });
      });
    },
    // 上传成功的回调函数-音频
    uploadAudioSuccess(res, file, fileList) {
      this.$message.success('音频上传成功');
      // console.log('文件上传成功', res, file, fileList);
      this.form.audioFile = res.data.url
      this.audioFileName = file.name
      // console.log(this.form)
    },
    // 上传失败的回调函数-音频
    uploadAudioError(error, file, fileList) {
      this.$message.error('音频上传失败');
    },
    // 删除已上传的音频文件
    changeAudioFile() {
      this.$confirm('是否删除已上传的音频文件？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form.audioFile = ''
        this.audioFileName = ''
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    //   背景图片上传
    // 上传前的验证函数
    beforeUploadPic(file) {
      const isAllowedType = this.checkFileType_pic(file);
      // const isAllowedSize = this.checkFileSize_pic(file);
      return new Promise((resolve, reject) => {
        if (isAllowedType) {
          resolve(true);
        } else {
          if (!isAllowedType) {
            this.$message.error('仅支持 png、jpg、jpeg、bmp 格式的图片文件');
          }
          // if (!isAllowedSize) {
          //   this.$message.error('文件大小不能超过 3 MB');
          // }
          reject(false);
        }
      });
    },
    // 检查文件类型-图片
    checkFileType_pic(file) {
      const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp'];
      return allowedTypes.includes(file.type);
    },
    // 检查文件大小-图片
    checkFileSize_pic(file) {
      const maxSize = 3 * 1024 * 1024; // 3 MB
      return file.size <= maxSize;
    },
    // 上传成功的回调函数-图片
    uploadPicSuccess(res, file, fileList) {
      this.$message.success('照片上传成功');
      this.form.picFile = res.data.url;
      console.log(this.form.picFile);
    },
    // 上传失败的回调函数-图片
    uploadPicError(error, file, fileList) {
      this.$message.error('照片上传失败');
    },

  },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai';
</style>